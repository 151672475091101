// This is the scss entry file
import "../styles/index.scss";
import Alpine from 'alpinejs';
import "@lottiefiles/lottie-player";
import LazyLoad from "vanilla-lazyload";
import ClipboardJS from 'clipboard';
import 'flowbite';
import {
	DataTable
} from "simple-datatables";
import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import curl from 'highlightjs-curl';
import ApexCharts from 'apexcharts';

import {
	Fancybox
} from "@fancyapps/ui";
import {
	gsap,
	Power3,
} from 'gsap';

import {
	ScrollTrigger
} from 'gsap/dist/ScrollTrigger.js';

import {
	Swiper,
	Navigation,
	Pagination,
	Controller,
	Autoplay
} from 'swiper';

export default class countUp {
	constructor(el) {
		this.el = el;
		this.setVars();
		this.init();
	}

	setVars() {
		this.number = this.el.querySelectorAll("[data-countup-number]");
		this.observerOptions = {
			root: null,
			rootMargin: "0px 0px",
			threshold: 0
		};
		this.observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				const end = parseFloat(
					entry.target.dataset.countupNumber.replace(/,/g, "")
				);
				const decimals = this.countDecimals(end);
				if (entry.isIntersecting) {
					this.iterateValue(entry.target, end, decimals);
				}
			});
		}, this.observerOptions);
	}

	init() {
		if (this.number.length > 0) {
			this.number.forEach((el) => {
				this.observer.observe(el);
			});
		}
	}

	iterateValue(el, end, decimals) {
		const start = 0;
		const duration = 2500;
		let startTimestamp = null;

		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const elapsedPercent = (timestamp - startTimestamp) / duration;
			const easedProgress = Math.min(this.easeOutQuint(elapsedPercent), 1);
			let interimNumber = Math.abs(easedProgress * (end - start) + start);
			el.innerHTML = this.formatNumber(interimNumber, decimals);
			if (easedProgress < 1) {
				window.requestAnimationFrame(step);
			}
		};

		// requestAnimationFrame returns DOMHighResTimeStamp as a callback (used as timestamp)
		window.requestAnimationFrame(step);
	}

	easeOutQuad(x) {
		return 1 - Math.pow(1 - x, 3);
	}

	easeOutQuint(x) {
		return 1 - Math.pow(1 - x, 5);
	}

	countDecimals(val) {
		if (Math.floor(val) === val) return 0;
		return val.toString().split(".")[1].length || 0;
	}

	formatNumber(val, decimals) {
		return val.toLocaleString("en-US", {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		});
	}
}

Swiper.use([Navigation, Controller, Autoplay, Pagination]);

let cx, cy, mouseX, mouseY, posX, posY, clientX, clientY, dx, dy, tiltx, tilty, request, radius, degree, x, y, cursorX, cursorY;

hljs.highlightAll();

window.document.addEventListener("DOMContentLoaded", function () {

	Fancybox.bind("[data-fancybox-form]", {
		on: {
			"closing": (fancybox, event) => {
				document.getElementById('audience_explorer').reset();
				console.log('closing');
			},
		},
	});

	function getUrlParameter(name) {
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        return results ? true : false;
    }

    if (getUrlParameter('explorer')) {
        Fancybox.show([{ src: "#explorer-form", type: "inline" }]);
    }

	const chartExplorer = document.getElementById("donut-chart");
	const getChartOptions = (notAWallet, freshWalletCount, depositWalletCount, outboundWalletCount) => {
		return {
			series: [notAWallet, freshWalletCount, depositWalletCount, outboundWalletCount],
			colors: ["#EB5757", "#F2994A", "#9E7EFF", "#81FF9C"],
			states: {
				normal: {
					filter: {
						type: 'none',
						value: 0,
					}
				},
				hover: {
					filter: {
						type: 'lighten',
						value: 0.2,
					}
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'darken',
						value: 0.35,
					}
				},
			},
			chart: {
				height: 320,
				width: "100%",
				type: "donut",
				foreColor: '#fff'
			},
			stroke: {
				colors: ["transparent"],
				lineCap: "",
			},
			tooltip: {
				enabled: true,
				enabledOnSeries: undefined,
				shared: true,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: undefined,
				hideEmptySeries: true,
				fillSeriesColor: false,
				theme: 'dark',
			},
			plotOptions: {
				pie: {
					donut: {
						labels: {
							show: true,
							name: {
								show: false,
								fontFamily: "Inter, sans-serif",
								offsetY: 20,
							},
							total: {
								showAlways: true,
								show: true,
								label: "Unique visitors",
								fontFamily: "Inter, sans-serif",
								formatter: function (w) {
									const sum = w.globals.seriesTotals.reduce((a, b) => {
										return a + b;
									}, 0);
									return '$' + sum + 'k';
								},
							},
							value: {
								show: false,
								fontFamily: "Inter, sans-serif",
								fontWeight: "bold",
								offsetY: -20,
								formatter: function (value) {
									return value + "k";
								},
							},
						},
						size: "50%",
						customScale: 0.8
					},
				},
			},
			grid: {
				padding: {
					top: -2,
				},
			},
			labels: ["Not a Wallet", "Fresh Wallet", "Deposit Wallet", "Outbound  Wallet"],
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: true,
				position: "bottom",
				fontFamily: "Inter, sans-serif",
				onItemHover: {
					highlightDataSeries: true
				},
			},
			yaxis: {
				// labels: {
				// 	formatter: function (value) {
				// 		return value + "kkk";
				// 	},
				// },
			},
			xaxis: {
				labels: {
					formatter: function (value) {
						return value + "kkkk";
					},
				},
				axisTicks: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
			},
		};
	};

	if (chartExplorer && typeof ApexCharts !== 'undefined') {
		let notAWallet = chartExplorer.getAttribute("data-not-a-wallet");
		let freshWalletCount = chartExplorer.getAttribute("data-fresh-wallet-count");
		let depositWalletCount = chartExplorer.getAttribute("data-deposit-wallet-count");
		let outboundWalletCount = chartExplorer.getAttribute("data-outbound-wallet-count");
		notAWallet = parseFloat(notAWallet);
		freshWalletCount = parseFloat(freshWalletCount);
		depositWalletCount = parseFloat(depositWalletCount);
		outboundWalletCount = parseFloat(outboundWalletCount);
		const chart = new ApexCharts(chartExplorer, getChartOptions(notAWallet, freshWalletCount, depositWalletCount, outboundWalletCount));
		chart.render();
	}

	hljs.registerLanguage('python', python);
	hljs.registerLanguage('curl', curl);
	// clipboard 
	document.querySelectorAll('.js-clipboard').forEach(function (element) {
		let clipboard = new ClipboardJS(element);

		clipboard.on('success', function (e) {
			e.trigger.querySelector('.copy-button').textContent = "Copied!";
			e.clearSelection();
			setTimeout(function () {
				e.trigger.querySelector('.copy-button').textContent = "Copy";
			}, 2500);
		});
	});

	document.querySelectorAll('.clipboard-button').forEach(function (element) {
		let clipboard = new ClipboardJS(element);

		clipboard.on('success', function (e) {
			console.log(e.trigger);
			e.trigger.classList.add('copied');
			e.clearSelection();
			setTimeout(function () {
				e.trigger.classList.remove('copied');
			}, 500);
		});
	});

	gsap.registerPlugin(ScrollTrigger);

	let delSections = document.querySelectorAll(".plax");

	delSections.forEach(section => {

		let imageAnim = gsap.to(section.querySelector("img"), {
			y: "-100vh",
			ease: "none",
			paused: true
		});

		let progressTo = gsap.quickTo(imageAnim, "progress", {
			ease: "power3",
			duration: parseFloat(section.dataset.scrub) || 0.1
		});

		gsap.to(section.querySelector(".plax-inner"), {
			y: "100vh",
			yPercent: -20,
			ease: "none",
			scrollTrigger: {
				scrub: true,
				trigger: section,
				start: "top bottom",
				end: "bottom top",
				onUpdate: self => progressTo(self.progress)
			}
		});

	});

	window.Alpine = Alpine;
	Alpine.start();

	const cursor = document.getElementById('cursorbg');

	mouseX = 0, mouseY = 0, posX = 0, posY = 0, cursorX = 0, cursorY = 0;

	function mouseCoords(e) {

		mouseX = e.pageX;
		mouseY = e.pageY;

	}

	let cursorBg = document.getElementById('bg-cursor');

	if (cursorBg) {
		cursorBg.addEventListener('mousemove', e => {
			cursorX = e.pageX - cursorBg.offsetLeft;
			cursorY = e.pageY - cursorBg.offsetTop;
		});

		gsap.to({}, .01, {

			repeat: -1,

			onRepeat: () => {

				posX += (cursorX - posX) / 20;
				posY += (cursorY - posY) / 20;

				gsap.set(cursor, {
					css: {
						left: posX - 160,
						top: posY - 160
					}
				});

			}

		});
	}

	// Vegas slider
	const swiper = new Swiper('.vegas-slider', {
		// modules: [Pagination, Navigation],
		speed: 400,
		spaceBetween: 20,
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		breakpoints: {
			565: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 3,
			}
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
	});

	// What we are reading slider
	const wwrSlider = new Swiper('.wwr-slider', {
		speed: 400,
		spaceBetween: 20,
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
			}
		},
		navigation: {
			nextEl: '.wwr-button-next',
			prevEl: '.wwr-button-prev',
		},
	});

	// Metrics slider
	let metrics = document.querySelector(".metrics");

	if (metrics) {
		const speed = 50;

		let original_html = metrics.innerHTML;
		let new_html = "<div class='metrics-items inline-flex'>" + original_html + "</div>";
		metrics.innerHTML = new_html;
		metrics.innerHTML += new_html;

		let tickerWidth = document.querySelector(".metrics-items").offsetWidth;
		let initDuration = tickerWidth / speed;

		gsap.to(".metrics-items", {
			duration: initDuration,
			xPercent: -100,
			ease: "none",
			repeat: -1
		});
	}

	let insightsSvg = document.getElementById("insights_illustration");

	if (window.innerWidth > 1024) {
		if (insightsSvg) {
			let insights = gsap.timeline();

			insights
				.add(
					gsap.to(".pulse", {
						scale: 1.1,
						opacity: 0,
						duration: 2,
						transformOrigin: "center center",
						stagger: {
							each: 0.5,
							repeat: -1
						}
					})
				);

			let wallets = gsap.utils.toArray(".wallet"),
				results = gsap.utils.toArray(".wallet-result"),
				checks = gsap.utils.toArray(".wallet-check"),
				tl = gsap.timeline({
					repeat: -1
				});

			wallets.forEach((wallet, index) => {
				tl
					.set(wallet, {
						transformOrigin: "-50% 50%"
					})
					.fromTo(wallet, {
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						duration: 0.5
					})
					.from(wallet, {
						left: '-100%',
						duration: 2.5,
						ease: "expo.out"
					}, "<")
					.to(wallet, {
						autoAlpha: 0,
						duration: 0.5
					}, "-=1")
					.fromTo(results[index], {
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						duration: 1
					})
					.from(results[index], {
						right: '30%',
						duration: 2,
						ease: "expo.out"
					}, "<")
					.fromTo(checks[index], {
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						duration: 0.5
					})
					.to(checks[index], {
						top: '110%',
						duration: 2,
						ease: "expo.out"
					}, "<")
					.to(results[index], {
						autoAlpha: 0,
						duration: 1,
						ease: "expo.out"
					}, "> 3");
			});
		}
	}

	// Lazyload images
	var lazyLoad = new LazyLoad({
		elements_selector: ".lazy",
		cancel_on_exit: true
	});

	// Lazy elfsight
	window.lazyFunctions = {
		elfsight: function (element) {
			let scriptTag = document.createElement('script');
			scriptTag.setAttribute("src", "https://apps.elfsight.com/p/platform.js");
			element.appendChild(scriptTag);
			element.classList.add("loaded");
		},
		//   sendfox: function (element) {
		//     scriptTag = document.createElement('script');
		//     scriptTag.setAttribute("src", "https://sendfox.com/js/form.js");
		//     scriptTag.setAttribute("async", "");
		//     element.appendChild(scriptTag);
		//     element.classList.add("loaded");
		//   }
	};

	function executeLazyFunction(element) {
		var lazyFunctionName = element.getAttribute("data-lazy-function");
		var lazyFunction = window.lazyFunctions[lazyFunctionName];
		if (!lazyFunction) return;
		lazyFunction(element);
	}

	var ll = new LazyLoad({
		unobserve_entered: true, // <- Avoid executing the function multiple times
		callback_enter: executeLazyFunction // Assigning the function defined above
	});


	//Accordeon

	var accordeonButtons = document.getElementsByClassName("accordeon-title");

	for (var i = 0; i < accordeonButtons.length; i++) {
		var accordeonButton = accordeonButtons[i];

		accordeonButton.addEventListener("click", toggleItems, false);
	}

	function toggleItems() {

		var itemClass = this.className;

		for (var i = 0; i < accordeonButtons.length; i++) {
			accordeonButtons[i].className = "accordeon-title closed";
		}

		var pannels = document.getElementsByClassName("accordeon-content");
		for (var z = 0; z < pannels.length; z++) {
			pannels[z].style.maxHeight = 0;
		}

		if (itemClass == "accordeon-title closed") {
			this.className = "accordeon-title active";
			var panel = this.nextElementSibling;
			panel.style.maxHeight = panel.scrollHeight + "px";
		}

	}

	// tabs 

	let tabButtons = document.querySelectorAll(".tab-btn");
	let gliderElement = document.querySelector('.glider');
	for (let i = 0; i < tabButtons.length; i++) {
		if (tabButtons[i].classList.contains("current")) {
			gliderElement.style.width = tabButtons[i].offsetWidth + 'px';
		}
		let tabButton = tabButtons[i];
		tabButton.addEventListener("click", openTab, false);

	}



	function openTab() {

		let itemClass = this.className;
		let currentElement = this;

		if (this.getAttribute("tab-direction") == "right") {
			let buttoWidth = document.querySelector('.tab-btn.current');
			gliderElement.style.setProperty('--offset', buttoWidth.offsetWidth + 'px');
			gliderElement.style.width = this.offsetWidth + 'px';
		} else {
			gliderElement.style.setProperty('--offset', '0px');
			gliderElement.style.width = this.offsetWidth + 'px';
		}
		if (currentElement.classList.contains("current")) {
			return;
		}

		let direction = currentElement.getAttribute("tab-direction");

		tabButtons.forEach(function (tabButtons) {
			tabButtons.classList.remove("left", "right");
		});

		tabButtons.forEach(function (tabButtons) {
			tabButtons.classList.remove("current");
		});

		// currentElement.classList.add("active", direction);

		for (let i = 0; i < tabButtons.length; i++) {
			tabButtons[i].classList.remove('current');
		}

		let tabContents = document.getElementsByClassName("tab-content");
		for (let z = 0; z < tabContents.length; z++) {
			tabContents[z].classList.remove('current');
		}

		this.classList.add('current', direction);
		document.getElementById(this.getAttribute('data-tab')).classList.add('current');

	}


	// Simplifed version of Viget dynamic modules to attach instances for this demo
	// https://www.viget.com/articles/how-does-viget-javascript/
	// You CAN use this pattern, but it's single purpose right now
	const dataModules = [...document.querySelectorAll('[data-module="countup"]')];

	dataModules.forEach((element) => {
		element.dataset.module.split(" ").forEach(function () {
			new countUp(element);
		});
	});

	// radial chart
	function calculateColor(percent) {
		let rStart = 235, gStart = 87, bStart = 87;
		let rEnd = 129, gEnd = 255, bEnd = 156;
	
		let r = Math.floor(rStart + (rEnd - rStart) * (percent / 100));
		let g = Math.floor(gStart + (gEnd - gStart) * (percent / 100));
		let b = Math.floor(bStart + (bEnd - bStart) * (percent / 100));
	
		return `rgb(${r},${g},${b})`;
	}
	
	function createCircleChart(percent, color, size, stroke, showPercentSign = true) {
		let svg = `<svg class="mkc_circle-chart" viewbox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
			<path class="mkc_circle-bg" stroke="#293670" stroke-width="${stroke}" fill="none" d="M18 2.0845
				  a 15.9155 15.9155 0 0 1 0 31.831
				  a 15.9155 15.9155 0 0 1 0 -31.831"/>
			<path class="mkc_circle" stroke="${color}" stroke-width="${stroke}" stroke-dasharray="${percent},100" stroke-linecap="round" fill="none"
				d="M18 2.0845
				  a 15.9155 15.9155 0 0 1 0 31.831
				  a 15.9155 15.9155 0 0 1 0 -31.831" />
			<text class="mkc_info" x="50%" y="50%" alignment-baseline="central" text-anchor="middle" font-size="8" class="text-white">
				${percent}${showPercentSign ? '%' : ''}
			</text>
		</svg>`;
		return svg;
	}
	
	let charts = document.getElementsByClassName('mkCharts');

	for (let i = 0; i < charts.length; i++) {
		let chart = charts[i];
		let percent = chart.dataset.percent;
		let color = ('color' in chart.dataset) ? chart.dataset.color : "#2F4F4F";
		let size = ('size' in chart.dataset) ? chart.dataset.size : "100";
		let stroke = ('stroke' in chart.dataset) ? chart.dataset.stroke : "1";
	
		if (chart.classList.contains('mkCharts--color')) {
			color = calculateColor(percent);
			charts[i].innerHTML = createCircleChart(percent, color, size, stroke, false);
		} else {
			charts[i].innerHTML = createCircleChart(percent, color, size, stroke);
		}
	}

});